html, body {
  background-color: #1f1f1f;
  color: whitesmoke;
  overscroll-behavior-y: none;
}

.page {
  background: #1f1f1f;
  color: whitesmoke;
  position: absolute;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.navbar {
  position: relative;
  height: 88px;
}

.nav-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: rgba(31, 31, 31, 0.95);
  border: 2px solid #424242;
  width: 450px;
  height: 60px;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-size: 28px;
  font-weight: bold;
  border-radius: 8px;
}

.page-content {
  position: relative;
  display: flex;
  align-self: center;
  margin-top: 8px;
}

.item-container {
  display: flex;
  border: 1.5px solid #424242;
  background-color: rgba(23, 23, 23, 0.88);
  border-radius: 8px;
}

.center-flex {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;  
}

.center-vert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-hor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centralize {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.no-highlight {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.hover:hover {
  scale: 110%;
  cursor: pointer;
}

.fill {
  width: 100%;
  height: 100%;
}

.words-container {
  position: relative;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 4px;
}

.no-scroll {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.keyboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

/* Wordle UI Elements */

.wordle-ui-container {
  position: absolute;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 95%;
}

.wordle-advanced-container {
  display: flex;
  height: 80%;
  justify-content: space-between;
}

/* Custom Popup */

.popup-background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.69);
  z-index: 8000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-device-width: 660px) {
  .navbar {
    height: 69px;
  }

  .nav-title {
    height: 42px;
    width: 200px;
    font-size: 18px;
  }
  
  .wordle-slider-container {
    height: 180px;
  }
}